.home-content {
  height: 100%;
  width: 100%;
}
.logo{
  width: 182px;
  height: 60px;
  background: url('../../imagesData/logo.png') no-repeat center;
  background-size: 100% 100%;
  position: absolute;
  left: 89px;
  top: 20px;
  z-index: 11;
}
.home-first-content {
  position: relative;
  overflow: hidden;
}
.home-first-login {
  display: flex;
  position: absolute;
  right: 20%;
  top: 50%;
  z-index: 222;
  transform: translateY(-50%);
}
.home-first-content {
  height: 100vh;
  width: 100%;
  background-image: url('../../imagesData/login_person.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: -51px -537px;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(96deg, rgba(16, 15, 34, 0.3) 0%, rgba(15, 20, 34, 0.4) 49%, rgba(31, 9, 78, 0.5) 100%);
  backdrop-filter: blur(40.3px);
}

.bg2 {
  width: 608px;
  height: 839px;
  position: absolute;
  left: 288px;
  bottom: 0;
  background-image: url('../../imagesData/login_person.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
