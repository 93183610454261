.dialog-data-content {
  box-sizing: border-box;
  position: relative;
  z-index: 222;
}

/* // */

.LoginItemContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 72px;
  color: rgba(22, 21, 24, 1);
}

.LoginItemContainer.loading {
  cursor: pointer;
}
.close-icon {
  box-sizing: border-box !important;
  width: 100%;
  text-align: right;
}
.verification-code {
  color: #ffffff;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 32px;
}

.dialog-data-content .phone-data,
.dialog-data-content .code-number {
  margin-bottom: 24px;
  width: 280px;
}
.dialog-data-content .code-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-data-content .code-input {
  width: 170px;
}

.dialog-data-content .input-container {
  height: 40px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  padding: 0 11px;
  display: flex;
  align-items: center;
  position: relative;
}

.dialog-data-content .person-icon,
.dialog-data-content .code-icon {
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.dialog-data-content .person-icon {
  background-image: url('../../imagesData/user.png');
}

.dialog-data-content .code-icon {
  background-image: url('../../imagesData/mail.png');
}

input {
  outline: none;
}
.dialog-data-content .input-container input {
  border: none;
  height: 100%;
  padding: 6px 9px 8px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  flex: 1;
  width: 0;
  box-sizing: border-box;
}
.dialog-data-content .input-container input::placeholder {
  font-weight: 400;
  color: #7d7d7d;
  line-height: 24px;
  font-size: 16px;
}
.dialog-data-content .correct-circle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}
.auto-login {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.auto-login-checked {
  width: 16px;
  height: 16px;
  background: url('./../../imagesData/checked.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 8px;
}
.auto-login-notChecked {
  width: 16px;
  height: 16px;
  background: url('./../../imagesData/noChecked.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 8px;
}
.checked-status {
  color: #ffffff;
}
.notChecked-status {
  color: rgba(125, 125, 125, 1);
}

.code-number .get-code {
  display: inline-block;
  vertical-align: middle;
  width: 102px;
  height: 40px;
  background-color: #5e24ff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  cursor: pointer;
  margin-left: 2px;
  text-align: center;
}
.checked-statue {
  margin-top: 24px;
  padding-left: 64px;
  position: relative;
}
.checked-statue .not-checked-icon {
  width: 16px;
  height: 16px;
  background: url('./../../imagesData/noChecked.png') no-repeat center;
  background-size: 100%;
}
.checked-statue .checked-icon {
  width: 16px;
  height: 16px;
  background: url('./../../imagesData/checked.png') no-repeat center;
  background-size: 100%;
}

.checked-statue div {
  display: inline-block;
  vertical-align: middle;
}
.checked-statue div:nth-child(2) {
  margin-left: 8px;
}
.checked-statue .login-data-word {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  margin-left: 8px;
  color: #ffffff;
  opacity: 1;
}
.dialog-data-content .login-toast{
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 14px;
  line-height: 24px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 130%);
  text-wrap: nowrap;
}
.login-btn > .ui {
  display: inline-block;
  width: 280px;
  height: 40px;
  background: #5e24ff;
  border-radius: 2px;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #ffffff;
  opacity: 1;
  padding: 0;
}
.LoginItemContainer .ui.loader.massive {
  box-sizing: border-box;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.LoginItemContainer .loader-background {
  border-radius: 24px;
  background-color: rgba(67, 64, 74, 0.9);
  position: absolute;
  display: flex;
  flex-direction: column;
  color: white;
  width: 366px;
  height: 492px;
  top: -156px;
  z-index: 555;
}
.dialog-data-content .loader-background {
  border-radius: 24px;
  background-color: rgba(67, 64, 74, 0.9);
  position: absolute;
  display: flex;
  flex-direction: column;
  color: white;
  width: 120%;
  height: 120%;
  top: 50%;
  left: 50%;
  z-index: 5555;
  transform: translate(-50%, -50%);
}
.loader-background .ui.massive.loader:after,
.ui.massive.loader:before,
.loader-background .ui.massive.loader {
  width: 70px;
  height: 70px;
  margin-left: -19px;
}

.LoginItemContainer .loader-background .ui.loader {
  position: relative;
  top: auto;
  left: auto;
  transform: unset;
}

.LoginItemContainer .loader-background .ui.button {
  color: var(--primary);
  font-size: 14px;
  line-height: 17px;
  border-radius: 12px;
  padding: 14px 46px;
  min-width: auto;
}
.LoginItemContainer .LoginItemContainer__BackLayer {
  position: absolute;
  box-sizing: border-box;
}

.LoginItemContainer .LoginItemContainer__Content {
  width: 516px;
  height: 492px;
  background: url('./../../imagesData/bcLogin.png') no-repeat center;
  background-size: 100%;
  box-sizing: border-box;
  z-index: 222;
  position: absolute;
}

.LoginItemContainer .LoginItemContainer__Content h2 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  transform: translate(-3px, 50px);
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform ease 0.3s;
}

.LoginItemContainer .LoginItemContainer__Content p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(22, 21, 24, 1);
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 40px;
  transform: translate(0, 360px);
  transition: transform ease 0.3s;
}

.LoginItemContainer .LoginItemContainer__Content img {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(11px, 60px);
  transition: transform ease 0.3s;
}

.LoginItemContainer .LoginItemContainer__Content .wallet-img {
  top: 35px;
}

.LoginItemContainer .LoginItemContainer__Content .ui.button,
.LoginItemContainer .LoginItemContainer__Content .ui.button {
  position: absolute !important;
  border-radius: 12px;
  bottom: 30px;
  transition: opacity ease 0.3s;
  width: 280px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: none;
  height: 56px;
}

.LoginItemContainer.active .LoginItemContainer__Content .ui.button,
.LoginItemContainer:hover .LoginItemContainer__Content .ui.button {
  opacity: 1 !important;
}