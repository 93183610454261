
.LoadingRender {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #20293a;
}
.loading-logo{
  width: 226px;
  height: 226px;
  background: url('./../../../imagesData/loadingLogo.png') no-repeat center;
  background-size: cover;
  margin-bottom: 20px;
}
.coming-soon-action {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 888;
  border-radius: 50%;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}
.coming-blank{
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  margin: 0 auto;
  transform: scale(0.85);
  z-index: -1;
  filter: blur(5vw);
  background: linear-gradient(270deg, #00fcbd, #7400f9);
  background-size: 150% 150%;
  border-radius: 50%;
  animation: glowmation 10s linear infinite;
  z-index: 777;
}
@keyframes glowmation {
  0% {
    top: -3%;
    left: -3%;
    background-position: 0% 50%;
    background-size: 150% 150%;
  }
  12.5% {
    top: -3%;
    left: 0;
    background-size: 70% 30%;
  }
  25% {
    top: -3%;
    left: 3%;
    background-size: 100% 50%;
  }
  37.5% {
    top: 0;
    left: 3%;
    background-size: 70% 30%;
  }
  50% {
    top: 3%;
    left: 3%;
    background-position: 100% 50%;
    background-size: 30% 30%;
  }
  62.5% {
    top: 3%;
    left: 0;
    background-size: 30% 70%;
  }
  75% {
    top: 3%;
    left: -3%;
    background-size: 50% 100%;
  }
  87.5% {
    top: 0;
    left: -3%;
    background-size: 30% 70%;
  }
  100% {
    top: -3%;
    left: -3%;
    background-position: 0% 50%;
    background-size: 150% 150%;
  }
}
.coming-loading-word{
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -20%);
  font-size:20px;
  font-weight: bold;
  color:#fff;
}